<template>
  <ValidationObserver ref="validationObserver" slim>
    <tr>
      <TableData :rules="'required'" :hasError="hasError" :success="company || success" v-if="!isCantFindCompany">
        <vue-instant
          ref="typeAhead"
          name="company"
          placeholder="Company name"
          v-model="typeahead.value"
          :suggestion-attribute="typeahead.suggestionAttribute"
          :show-autocomplete="true"
          :autofocus="false"
          :findSimilar="false"
          :allowSimilar="true"
          :suggestions="typeahead.suggestions"
          :suggestOnAllWords="false"
          @selected="selectCompany"
          @enter="handleOnEnter"
        />
      </TableData>
      <TableData :rules="'required'" :hasError="hasError" :success="success" v-else>
        <input class='company-name' name="companyName" v-model="companyName" />
      </TableData>
      <TableData :rules="'required'" :hasError="hasError" :success="success">
        <select name="isCantFindCompany" v-model="isCantFindCompany">
          <option v-for="type in booleanOptions" :key="type.label" :value="type.value">{{ type.label }}</option>
        </select>
      </TableData>
      <TableData :rules="'required'" :hasError="hasError" :success="success">
        <select name="isSoleTrader" v-model="isSoleTrader" :disabled="!isCantFindCompany || company">
          <option v-for="type in booleanOptions" :key="type.label" :value="type.value">{{ type.label }}</option>
        </select>
      </TableData>
      <TableData :rules="!isCantFindCompany || isSoleTrader ? '' : 'required'" :hasError="hasError" :success="success">
        <input name="companyNumber" v-model="companyNumber" :disabled="!isCantFindCompany || isSoleTrader" />
      </TableData>
      <TableData :rules="'required'" :hasError="hasError" :success="success" v-if="$config.whitelabel.signUp.countryRequired">
        <select name="countryOfResidence" v-model="countryOfResidence" :disabled="!isCantFindCompany">
          <option v-for="type in countriesOptions" :key="type.label" :value="type.value">{{ type.label }}</option>
        </select>
      </TableData>
      <TableData :rules="'required'" :hasError="hasError" :success="success">
        <select name="fundingType" v-model="fundingType">
          <option v-for="type in fundingTypes" :key="type.label" :value="type.value">{{ type.label }}</option>
        </select>
      </TableData>
      <TableData :rules="'required'" :hasError="hasError" :success="success">
        <input name="turnoverLastYear" type="number" v-model="turnoverLastYear" />
      </TableData>
    </tr>
  </ValidationObserver>
</template>

<script>
import { fundingTypes } from '@/utils/constants'
import { setFundingApplication } from '@/api/fundingApplication'
import { findCompanies, createCompany } from '@/api/company'
import { authGetters } from '@/store/modules/auth/routines'
import { setFinancialData } from '@/api/financialData'
import { countriesOptions } from '@/utils/countries'
import { ValidationObserver } from 'vee-validate'
import TableData from './TableData'
import { mapGetters } from 'vuex'

export default {
  components: {
    ValidationObserver,
    TableData
  },
  props: {
    id: [Number, String]
  },
  data() {
    return {
      countriesOptions,
      companyId: null,
      fundingType: null,
      turnoverLastYear: null,
      companyNumber: null,
      isCantFindCompany: false,
      isSoleTrader: false,
      fundingTypes: [{ label: 'All', value: 'all' }, ...fundingTypes, { label: 'Commercial Mortgage', value: 'Commercial Mortgage' }],
      booleanOptions: [
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        }
      ],
      companyName: null,
      company: null,
      typeahead: {
        value: '',
        suggestionAttribute: 'title',
        suggestions: this.getSuggestions
      },
      countryOfResidence: this.$config.whitelabel.country.defaultCountry,
      hasError: false,
      success: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      user: authGetters.USER
    })
  },
  watch: {
    isCantFindCompany(isCantFindCompany) {
      if (isCantFindCompany) {
        this.company = null
      }
    }
  },
  methods: {
    getSourceType() {
      if (this.company) {
        return 'FromRegistrar'
      }

      if (this.isSoleTrader) {
        return 'SoleTrader'
      }

      return 'Entered'
    },
    countyrCode(c) {
      return c.countryCode ? `(${c.countryCode})` : ''
    },
    companyRegistration(c) {
      return c.companyRegistrationNumber ? `(ABN: ${c.companyRegistrationNumber})` : ''
    },
    companyRegistrationNumber(c) {
      return c.companyRegistrationNumber ? `(${c.companyRegistrationNumber})` : ''
    },
    getSuggestions(value, callback) {
      this.company = null
      if (value === '') return
      const requestData = {
        companyName: value,
        skip: 0,
        take: 0
      }
      const companiesPromise = findCompanies(requestData)
        .then(response =>
          response.data.companies
            .sort((a, b) => (a.name.toLowerCase().indexOf(value.toLowerCase()) > b.name.toLowerCase().indexOf(value.toLowerCase()) ? -1 : 1))
            .map(c => {
              if (this.$config.whitelabel.components.companySearch.countryCode) {
                return { ...c, title: `${c.name} ${this.countyrCode(c)} ${this.companyRegistrationNumber(c)}` }
              } else {
                return { ...c, title: `${c.name} ${this.companyRegistration(c)}` }
              }
            })
        )
        .catch(e => e)
      callback(companiesPromise)
    },
    selectCompany(company) {
      this.company = company
      this.companyNumber = company.companyRegistrationNumber
      this.isSoleTrader = false
      this.isCantFindCompany = false
    },
    handleOnEnter(ev) {
      ev.preventDefault()
    },
    clearCompany() {
      this.company = null
    },
    create() {
      if (!this.$refs.validationObserver || !this.$refs.validationObserver.validate) return
      this.$refs.validationObserver.validate().then(async (valid) => {
        if (!valid) return
        try {
          this.loading = true
          if (this.company && Array.isArray(this.user.companies) && this.user.companies.filter(company => company.registrationNumber === this.company.companyRegistrationNumber).length) {
            this.hasError = true
            this.loading = false
            return
          }
          const { data: { companyId } } = await createCompany({
            companyName: this.typeahead.value,
            registrationNumber: this.company ? this.company.companyRegistrationNumber : this.companyNumber,
            country: this.company ? this.company.countryCode : this.countryOfResidence,
            isCantFindCompany: this.isCantFindCompany,
            sourceType: this.getSourceType()
          })
          this.companyId = companyId
          let fundingType = [this.fundingType]
          if (this.fundingTypes.find(fundingType => fundingType.value === 'all')) {
            fundingType = this.fundingTypes.map(type => type.value).filter(type => type !== 'all')
          }
          await setFundingApplication({
            fundingType,
            turnoverLastYear: this.turnoverLastYear,
            companyId
          })
          await setFinancialData({
            turnoverLastYear: this.turnoverLastYear,
            companyId
          })
          this.$emit('success', this.id)
        } catch (error) {
          console.error(error)
          this.loading = false
          this.hasError = true
          return
        }
        this.success = true
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables';
tr {
  td {
    border: 1px solid var(--color-primary-100);
    padding: 0;
    input {
      width: 100%;
      border: none;
      outline: none;
      border-radius: 0;

      &.company-name {
        width: 300px;
      }
    }
    select {
      border: none;
      outline: none;
      border-radius: 0;
      height: 100%;
      width: 100%;
    }

    &.error {
      border-bottom: 1px solid var(--color-error-500);
    }

    &.success {
      border-bottom: 1px solid var(--color-success-500);
    }
  }
  .field {
    margin: 0;
    display: table-cell;
    .custom-search-box {
      position: relative;
      display: block;
      z-index: 11;
      width: 100px;
      /deep/ {
        .input[type='text'],
        .sbx-searchbox__input,
        .sbx-searchbox__input-placeholder {
          font-size: 1rem;
          line-height: 1.5em;
        }
        .searchbox {
          margin-bottom: 12px;
          .group {
            right: 16px;
          }
        }
        .ui.input {
          position: relative;
          -webkit-appearance: none;
          background-color: transparent;
          z-index: 0;
          .icon {
            position: absolute;
            left: 10px;
            top: 7px;
          }
        }
        .sbx-searchbox__input {
          width: 100%;
          background-color: transparent;
          padding-left: 2.25rem;
        }
        .sbx-searchbox__input-placeholder {
          position: absolute;
          top: 0;
          left: 0;
          -webkit-appearance: none;
          opacity: 0.5;
          z-index: -1;
          width: 100%;
          padding-left: 2.25rem;
        }
      }
    }

    .sbx-searchbox {
      z-index: auto;
      width: 300px;

      /deep/ {
        input {
          width: 100%;
          border: none;
          outline: none;
          border-radius: 0;
        }
      }
    }
  }
}
</style>
