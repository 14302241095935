<template>
  <ValidationProvider #default="{ valid, touched }" :rules="rules" slim>
    <td class="input field" :class="{ 'error': touched && (!valid || hasError), 'success': valid || success  }">
      <slot />
    </td>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
export default {
  props: {
    success: [Boolean, Object],
    rules: String,
    hasError: Boolean
  },
  components: { ValidationProvider }
}
</script>
