<template>
  <div>
    <header>
      <BackButton />
      <SwoopLogo />
    </header>
    <div class="wrapper">
      <p>Complete the form below to quickly onboard your clients. This will enable your Swoop funding manager to review your client portfolio prior to your first call.</p>
      <div class="top-controls">
        <div class="field">
          <input type="number" v-model="numberOfRowsToAdd" />
          <button class="btn btn-sm btn-tertiary" @click="addRow">Add rows</button>
        </div>
      </div>
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Company name</th>
              <th>Can't find company</th>
              <th>Sole trader</th>
              <th>Company number</th>
              <th v-if="$config.whitelabel.signUp.countryRequired">Country</th>
              <th>Funding type</th>
              <th>Previous year {{ this.$dictionary.turnover }}</th>
            </tr>
          </thead>
          <tbody>
            <Row v-for="row in rows" :id="row.id" :key="row.id" ref="rowsRef" @success="removeRow" />
          </tbody>
        </table>
      </div>
      <div class="controls">
        <button class="btn btn-sm btn-primary" @click="createRows">Submit</button>
      </div>
    </div>
    <modal name="showSuccessModal" height="auto" :scrollable="true" :width="560">
      <section class="prevent-skip-modal text-center">
        <p>
          {{ successAmount }} client(s) has been submitted, any unsuccessful uploads will still be visible in the list. Your funding manager will be in touch OR book a call
          <a target="_blank" rel="noopener" href="https://calendly.com/simon-swoop">here</a>.
        </p>
        <button class="ui button btn-primary circular business-gradient" type="button" @click="$modal.hide('showSuccessModal')">
          Okay
        </button>
      </section>
    </modal>
  </div>
</template>

<script>
import Row from './components/Row'
import { mapActions } from 'vuex'
import { userRoutine } from '@/store/modules/auth/routines'
import SwoopLogo from '@/components/SwoopLogo'
import BackButton from '@/components/BackBtn'

export default {
  components: {
    BackButton,
    SwoopLogo,
    Row
  },
  data() {
    return {
      numberOfRowsToAdd: 1,
      successAmount: 0,
      rows: [{ id: 1 }]
    }
  },
  mounted() {
    this.requestUser()
  },
  methods: {
    ...mapActions({
      requestUser: userRoutine.TRIGGER
    }),
    addRow() {
      const newRows = [...Array(Number(this.numberOfRowsToAdd)).keys()].map(i => i + 1)
      for (let i = 0; i < newRows.length; i++) {
        this.rows.push({
          id: this.rows.length + 1
        })
      }
    },
    removeRow(id) {
      this.$nextTick(() => {
        this.rows = this.rows.filter(row => row.id !== id)
      })
    },
    async createRows() {
      let amountCreated = 0
      for (let index = 0; index < this.$refs.rowsRef.length; index++) {
        await this.$refs.rowsRef[index].create(this.advisorCompanies)
        amountCreated = amountCreated + 1
      }
      if (amountCreated > 0) {
        this.showSuccess(amountCreated)
      }
    },
    showSuccess(amountCreated) {
      this.successAmount = amountCreated
      this.$modal.show('showSuccessModal')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables';

header {
  border-bottom: 1px solid var(--color-primary-100);
  box-shadow: $default-box-shadow;
  background-color: $color-white;
  padding: 15px;
  display: flex;
}

.wrapper {
  padding: 0.5rem 0;

  p {
    text-align: center;
    padding: 0;
    margin: 0;
  }
}

.table-wrapper {
  box-shadow: $default-box-shadow;
  overflow-y: scroll;
  margin: 10px 0;
  height: calc(100vh - 200px);

  table {
    background-color: $color-white;
    border-collapse: collapse;
    border-radius: 5px;
    width: 100%;

    th {
      background-color: $color-white;
      box-shadow: $default-box-shadow;
      white-space: nowrap;
      position: sticky;
      font-weight: 500;
      padding: 10px;
      z-index: 2;
      top: -1px;
    }
  }
}

.top-controls {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  height: 35px;

  .field {
    flex-direction: row;
    display: flex;
    margin: 0;
    height: 35px;

    input {
      border-radius: 100em 0 0 100em;
      height: 35px;
      width: 100px;
    }

    button {
      margin: 0;
      border-radius: 0 100em 100em 0;
    }
  }
}

.controls {
  padding: 5px;
  display: flex;
  justify-content: flex-end;
}
</style>
